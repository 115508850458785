class HeaderPrime extends HTMLElement {
    async connectedCallback() {
        if (this.isIE()) {
            return;
        }
        this.setElements();
        this.initStyles();
        this.bindEvents();
    }

    setElements() {
        this.headerStyles = window.getComputedStyle(this);
        this.headerHeight = this.offsetHeight;
        this.headerHeightWithMargin = this.headerHeight + parseFloat(this.headerStyles['marginTop']) + parseFloat(this.headerStyles['marginBottom']);
        this.headerTopbarHeight = this.querySelector('.header-prime-topbar-container').offsetHeight;
        this.headerNavigation = this.querySelector('header-prime-navigation');

        this.headerBaseStyle = 'z-index: 100; left: 0px; right: 0px; ';
        this.headerTransitionStyle = 'transition: top 0.4s ease; ';
        this.headerScrolledToTopStyle = 'position: absolute; top: 0px; ' + this.headerBaseStyle;
        this.headerScrollUpStyle = 'position: fixed; top: -' + this.headerTopbarHeight + 'px; ' + this.headerBaseStyle;
        this.headerScrollDownStyle = 'position: fixed; top: -' + this.headerHeight + 'px; ' + this.headerBaseStyle;
        this.currentStyleIndex = -1;

        this.currentScroll = window.scrollY;
        this.previousScroll = this.currentScroll;
        this.scrollOffset = 4;
    }

    initStyles() {
        document.body.style.paddingTop = this.headerHeightWithMargin + 'px';
        if (this.isScrolledOutOfHeader()) {
            this.setScrollDownStyle(false);
        } else {
            this.setScrolledToTopStyle();
        }
    }

    bindEvents() {
        window.addEventListener('scroll', () => {
            this.checkScroll();
        });
    }

    checkScroll() {
        this.currentScroll = window.scrollY;

        if (!this.isScrolledToTopStyleSet() && this.isScrolledToTop()) {
            this.setScrolledToTopStyle();
            this.removeStickyAttributesForAnalytics();

        } else if (!this.isScrollUpStyleSet() && this.isScrollingUp() && this.isScrolledOutOfHeader()) {
            this.setScrollUpStyle(this.isScrollDownStyleSet());
            this.addStickyAttributesForAnalytics();

        } else if (!this.isScrollDownStyleSet() && this.isScrollingDown() && this.isScrolledOutOfHeader()) {
            this.setScrollDownStyle(this.isScrollUpStyleSet());
            this.closeOverlay();
            this.closeFlyout();
        }

        this.previousScroll = this.currentScroll;
    }

    isScrolledToTop() {
        return this.currentScroll < this.headerTopbarHeight;
    }

    isScrollingUp() {
        return this.currentScroll < this.previousScroll - this.scrollOffset;
    }

    isScrollingDown() {
        return this.currentScroll > this.previousScroll + this.scrollOffset;
    }

    isScrolledOutOfHeader() {
        return this.currentScroll > this.headerHeightWithMargin;
    }

    isScrolledToTopStyleSet() {
        return this.currentStyleIndex === 0;
    }

    isScrollUpStyleSet() {
        return this.currentStyleIndex === 1;
    }

    isScrollDownStyleSet() {
        return this.currentStyleIndex === 2;
    }

    setScrolledToTopStyle() {
        this.currentStyleIndex = 0;
        this.style = this.headerScrolledToTopStyle;
    }

    setScrollUpStyle(withTransition) {
        this.currentStyleIndex = 1;
        this.style = withTransition ? this.headerScrollUpStyle + this.headerTransitionStyle : this.headerScrollUpStyle;
    }

    setScrollDownStyle(withTransition) {
        this.currentStyleIndex = 2;
        this.style = withTransition ? this.headerScrollDownStyle + this.headerTransitionStyle : this.headerScrollDownStyle;
    }

    addStickyAttributesForAnalytics() {
        this.setAttribute("component", "header-prime-sticky");
        this.querySelectorAll("[component^=header-prime]")
            .forEach(headerComponent => {
                let oldAttrValue = headerComponent.getAttribute("component");
                if (!oldAttrValue.startsWith("header-prime-sticky")) {
                    headerComponent.setAttribute("component", oldAttrValue.replace("header-prime", "header-prime-sticky"));
                }
            });
    }

    removeStickyAttributesForAnalytics() {
        this.setAttribute("component", "header-prime");
        this.querySelectorAll("[component^=header-prime-sticky]")
            .forEach(headerComponent => {
                let oldAttrValue = headerComponent.getAttribute("component");
                headerComponent.setAttribute("component", oldAttrValue.replace("header-prime-sticky", "header-prime"));
            });
    }

    closeOverlay() {
        if (!this.isFullscreenOverlay()) {
            this.querySelectorAll("header-global-login")
                .forEach(overlayComponent => {
                    if (!overlayComponent.isOverlayClosed) {
                        overlayComponent.toggleVisibility();
                    }
                });
        }
    }

    closeFlyout() {
        if (this.headerNavigation) {
            this.headerNavigation.removeFlyout();
        }
    }

    isIE() {
        return !!window.navigator.userAgent.match(/(MSIE|Trident)/);
    }

    isFullscreenOverlay() {
        return !window.matchMedia('(min-width: 480px)').matches;
    }
}

if (!customElements.get('header-prime')) {
    customElements.define('header-prime', HeaderPrime);
}
